<template>
  <div>
    <NModal
      v-model:show="modalShow"
      preset="dialog"
      :title="`${teacherAccount} IP监控设置`"
      style="width: 400px;"
      :show-icon="false"
      positive-text="提交"
      negative-text="取消"
      @positive-click="handleSubmit"
      @after-leave="closeModal"
      :mask-closable="false"
    >
      <NForm
        ref="formRef"
        require-mark-placement="left"
        style="padding: 20px 20px 0;"
        :model="formValue"
        :rules="formRules"
      >
        <NFormItem label="是否开启监控" path="monitor">
          <NRadioGroup v-model:value="formValue.monitor">
            <NRadio :value="1" style="margin-right: 15px;">开启</NRadio>
            <NRadio :value="0">关闭</NRadio>
          </NRadioGroup>
        </NFormItem>
        <NFormItem label="监控预警" path="countsBeforeWarn">
          <NInputNumber
            style="margin-right: 10px;"
            :show-button="false"
            :min="0"
            v-model:value="formValue.countsBeforeWarn"
            @blur="handleBlurCount"
          />
          次后发送预警
        </NFormItem>
      </NForm>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { NModal, NForm, NFormItem, NInputNumber, NRadioGroup, NRadio, useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { ipMonitorSetting } from '@/api/school-teacher.js';

  const emit = defineEmits(['list-update']);

  const { SUCCESS } = resStatusEnum;
  const message = useMessage();
  const loading = ref(false);

  const teacherAccount = ref('');

  const modalShow = ref(false);
  function handleSubmit() {
    formRef.value.validate(errors => {
      if (!errors) {
        loading.value = true;
        ipMonitorSetting(getReqData()).then(({ code, data }) => {
          if (code === SUCCESS) {
            modalShow.value = false;
            emit('list-update');
            message.success('设置成功');
          }
        }).finally(() => {
          loading.value = false;
        });
      }
    });
    return false;
  }
  function closeModal() {
    modalShow.value = false;
  }

  const formRef = ref(null);
  const formValue = reactive({
    teacherID: null,
    monitor: null,
    countsBeforeWarn: null
  });
  const formRules = {
    monitor: {
      type: 'number',
      required: true,
      trigger: 'change',
      message: '必选'
    },
    countsBeforeWarn: {
      type: 'number',
      required: true,
      trigger: 'blur',
      message: '必填'
    }
  };
  function handleBlurCount() {
    let count = formValue.countsBeforeWarn;
    if (count) {
      formValue.countsBeforeWarn = Math.round(count);
    }
  }

  function setFormValue({ id, username, monitor, monitor_count }) {
    teacherAccount.value = username;
    Object.assign(formValue, {
      teacherID: id,
      monitor: Number(monitor),
      countsBeforeWarn: Number(monitor_count) || 0
    });
  }
  function getReqData() {
    const { teacherID, monitor, countsBeforeWarn } = formValue;
    return {
      'Teacher[id]': teacherID,
      'Teacher[monitor]': monitor,
      'Teacher[monitor_count]': countsBeforeWarn
    };
  }

  defineExpose({
    openEdit(data) {
      setFormValue(data);
      modalShow.value = true;
    }
  });
</script>