<template>
  <div style="background-color: #fff;">
    <h1 style="margin: 0; padding: 20px; font-size: 16px; border-bottom: 1px solid #efeff5;">
      <span v-if="schoolName" class="primary-color">{{ schoolName }}</span>
      教师账号列表
    </h1>
    <div style="padding: 20px;">
      <NForm inline label-placement="left">
        <NFormItem label="账号/手机号：">
          <NInput v-model:value="formValue.account" clearable />
        </NFormItem>
        <NFormItem label="最后登录时间：">
          <NDatePicker
            type="datetime"
            style="width: 190px;"
            v-model:value="formValue.lastLogin"
            clearable
          />
        </NFormItem>
        <NFormItem label="状态：">
          <NSelect
            style="width: 85px;"
            v-model:value="formValue.status"
            :options="statusOptions"
            clearable
          />
        </NFormItem>
        <NFormItem label="监控IP：">
          <NInput v-model:value="formValue.ip" clearable />
        </NFormItem>
        <NFormItem>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton style="margin-left: 10px;" @click="handleClearSearch">清除</NButton>
        </NFormItem>
      </NForm>
      <NDataTable
        size="small"
        :columns="tableColumns"
        :data="tableData"
        :pagination="tablePagination"
        remote
      ></NDataTable>
    </div>

    <OperationLogs ref="operationLogsRef" />
    <IPMonitorSetModal ref="ipMonitorSetModalRef" @list-update="updateTableData" />
    <LoginLogsModal ref="loginLogsModalRef" @list-update="updateTableData" />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, h } from 'vue';
  import { useRoute } from 'vue-router';
  import { NForm, NFormItem, NInput, NDatePicker, NSelect, NDataTable, NTime, NSpace, NButton, NPopconfirm, useMessage, useDialog } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import OperationLogs from './components/OperationLogs.vue';
  import IPMonitorSetModal from './components/IPMonitorSetModal.vue';
  import LoginLogsModal from './components/LoginLogsModal.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  import {
    teacherList,
    teacherAccountStatus,
    passwordReset,
    loginLogsReset
  } from '@/api/school-teacher.js';

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);
  const route = useRoute();
  const message = useMessage();
  const dialog = useDialog();

  const schoolID = route.query.id;
  const schoolName = route.query.name;
  const accountStatusValMap = {
    ON: 1,
    OFF: 0
  };
  const accountStatusValNameMap = {
    0: '已停用',
    1: '使用中'
  };

  const statusOptions = Object.keys(accountStatusValNameMap).map(val => ({ label: accountStatusValNameMap[val], value: Number(val) }));
  function getDefaultFormValue() {
    return {
      account: '',
      lastLogin: null,
      status: null,
      ip: ''
    };
  }
  const formValue = reactive(getDefaultFormValue());
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function getSearchParams() {
    const obj = {};
    const account = formValue.account.trim();
    if (account) {
      obj.username = account;
    }
    if (formValue.lastLogin) {
      obj.last_login_time = formValue.lastLogin / 1000;
    }
    if (formValue.status !== null) {
      obj.status = formValue.status;
    }
    const ip = formValue.ip.trim();
    if (ip) {
      obj.ip = ip;
    }
    return obj;
  }
  function handleSearch() {
    handlePageChange(1);
  }
  function handleClearSearch() {
    resetFormValue();
    handleSearch();
  }

  const operationLogsRef = ref(null);
  const ipMonitorSetModalRef = ref(null);
  const loginLogsModalRef = ref(null);

  const tableColumns = [
    { title: '账号/手机号', key: 'username', align: 'center' },
    {
      title: '状态',
      align: 'center',
      render: ({ status }) => {
        let className = '';
        let txt = '';
        switch (Number(status)) {
          case accountStatusValMap.ON:
            className = 'success-color';
            txt = '使用中';
            break;
          case accountStatusValMap.OFF:
            className = 'error-color';
            txt = '已停用';
            break;
        }
        return h(
          'span',
          { class: className },
          txt
        );
      }
    },
    {
      title: '最后登录时间',
      align: 'center',
      render: ({ last_login_time }) => {
        if (last_login_time == '0') {
          return h('span', null, '-');
        } else {
          return h(
            NTime,
            { time: last_login_time * 1000 }
          );
        }
      }
    },
    {
      title: '是否监控登录IP',
      align: 'center',
      render: ({ monitor }) => {
        let className = '';
        let style = {};
        let txt = '';
        switch (Number(monitor)) {
          case accountStatusValMap.ON:
            className = 'primary-color';
            txt = '监控中';
            break;
          case accountStatusValMap.OFF:
            style.color = '#ccc';
            txt = '未监控';
            break;
        }
        return h(
          'span',
          { class: className, style },
          txt
        );
      }
    },
    { title: 'IP登录数', key: 'school_ip_login_count', align: 'center' },
    {
      title: '最后登录IP及地点',
      align: 'center',
      render: ({ ip, province, city }) => h(
        'span',
        null,
        [ip, province, city].filter(item => Boolean(item)).join(' ')
      )
    },
    {
      title: '操作',
      align: 'center',
      width: '200px',
      render: rowData => {
        const { id, status, username } = rowData;
        const arr = [];
        if (ownUrlPermission('teacher/state')) {
          let type = '';
          let txt = '';
          let reqStatus;
          let changeStatus;
          switch (Number(status)) {
            case accountStatusValMap.ON:
              type = 'error';
              txt = '禁用';
              reqStatus = 1;
              changeStatus = accountStatusValMap.OFF;
              break;
            case accountStatusValMap.OFF:
              type = 'success';
              txt = '启用';
              reqStatus = 2;
              changeStatus = accountStatusValMap.ON;
              break;
          }
          arr.push(h(
            NPopconfirm,
            {
              onPositiveClick: () => {
                loading.value = true;
                teacherAccountStatus({
                  'Teacher[id]': id,
                  'Teacher[status]': reqStatus
                }).then(({ code }) => {
                  if (code === SUCCESS) {
                    message.success(`教师账号“${username}”已${txt}`);
                    // updateTableData();
                    rowData.status = changeStatus;
                  }
                }).finally(() => {
                  loading.value = false;
                });
              }
            },
            {
              trigger: () => h(
                NButton,
                { type, text: true },
                { default: () => txt }
              ),
              default: () => `确定${txt}此教师账号？`
            }
          ));
        }
        if (ownUrlPermission('teacher/reset')) {
          arr.push(h(
            NPopconfirm,
            {
              onPositiveClick: () => {
                loading.value = true;
                passwordReset({
                  'Teacher[id]': id,
                }).then(({ code }) => {
                  if (code === SUCCESS) {
                    message.success(`教师账号“${username}”密码已重置`);
                  }
                }).finally(() => {
                  loading.value = false;
                });
              }
            },
            {
              trigger: () => h(
                NButton,
                { type: 'primary', text: true },
                { default: () => '密码重置' }
              ),
              default: () => `确定重置此教师账号密码？`
            }
          ));
        }
        if (ownUrlPermission('teacher/operation-log')) {
          arr.push(h(
            NButton,
            { type: 'primary', text: true, onClick: () => { operationLogsRef.value.initTableData({ id, username }); } },
            { default: () => '操作日志' }
          ));
        }
        if (ownUrlPermission('teacher/teacher-ip')) {
          arr.push(h(
            NButton,
            { type: 'primary', text: true, onClick: () => { ipMonitorSetModalRef.value.openEdit(rowData) } },
            { default: () => 'IP监控设置' }
          ));
        }
        if (ownUrlPermission('teacher/login-log')) {
          arr.push(h(
            NButton,
            { type: 'primary', text: true, onClick: () => { loginLogsModalRef.value.openModal(rowData); } },
            { default: () => '登录记录' }
          ));
        }
        if (ownUrlPermission('teacher/monitor-log-reset')) {
          arr.push(h(
            NButton,
            {
              type: 'warning', text: true,
              onClick: () => {
                dialog.warning({
                  title: '重置确认',
                  content: `确定重置教师账号：“${username}”的IP登录记录？`,
                  positiveText: '确定',
                  negativeText: '取消',
                  onPositiveClick: () => {
                    loading.value = true;
                    loginLogsReset({
                      'Teacher[id]': id
                    }).then(({ code }) => {
                      if (code === SUCCESS) {
                        updateTableData();
                        message.success('重置成功');
                      }
                    }).finally(() => {
                      loading.value = false;
                    });
                  },
                  onNegativeClick: () => {}
                });
              }
            },
            { default: () => '重置IP登录记录' }
          ));
        }
        return h(
          NSpace,
          { size: [7, 0] },
          { default: () => arr }
        );
      }
    }
  ];
  const tableData = ref([]);
  const tablePagination = reactive({
    page: 1,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true,
    itemCount: 0,
    onChange: handlePageChange,
    onUpdatePageSize: pageSize => {
      tablePagination.pageSize = pageSize;
      handlePageChange(1);
    }
  });
  function getPaginationParams() {
    const { page, pageSize: page_size } = tablePagination;
    return {
      page,
      page_size
    }
  }
  function updateItemCount(count) {
    tablePagination.itemCount = Number(count) || 0;
  }
  function handlePageChange(page) {
    tablePagination.page = page;
    updateTableData();
  }
  function updateTableData() {
    loading.value = true;
    teacherList({
      'school_id': schoolID,
      ...getSearchParams(),
      ...getPaginationParams()
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { list, total } = data;
        tableData.value = Array.isArray(list) ? list : [];
        updateItemCount(total);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  updateTableData();
</script>