import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

export function teacherList(data) {
  return request({
    url: '/backend/web/teacher/index',
    method: GET,
    params: data
  });
}

export function teacherAccountStatus(data) {
  return request({
    url: '/backend/web/teacher/state',
    method: POST,
    data
  });
}

export function passwordReset(data) {
  return request({
    url: '/backend/web/teacher/reset',
    method: POST,
    data
  });
}

export function operationLogs(data) {
  return request({
    url: '/backend/web/teacher/operation-log',
    method: GET,
    params: data
  });
}

export function ipMonitorSetting(data) {
  return request({
    url: '/backend/web/teacher/teacher-ip',
    method: POST,
    data
  });
}

export function loginLogs(data) {
  return request({
    url: '/backend/web/teacher/login-log',
    method: GET,
    params: data
  });
}

export function loginLogsDel(data) {
  return request({
    url: '/backend/web/school/monitor-log-delete',
    method: POST,
    data
  });
}

export function loginLogsReset(data) {
  return request({
    url: '/backend/web/teacher/monitor-log-reset',
    method: POST,
    data
  });
}