export const accountOperationTypeVal = {

};

export const accountOperationTypeValName = {
  1: '教材',
  2: '教案',
  3: '习题',
  4: '试卷',
  5: '课程',
  6: '实训',
  7: '角色',
  8: '账号',
  9: '标签',
  10: '课堂',
  11: '班级',
  12: '学生',
  13: '教师',
  14: '学校',
  15: '常见问题',
  16: '离线留言',
  17: '子系统账号'
};