<template>
  <div>
    <NModal
      preset="card"
      :mask-closable="false"
      :close-on-esc="false"
      size="small"
      style="width: 1000px;"
      :title="modalTitle"
      v-model:show="modalDisplay"
      @after-leave="handleModalClosed"
    >
      <template #default>
        <NForm size="small" inline label-placement="left">
          <NFormItem label="关键词：">
            <NInput v-model:value="formValue.keyword" clearable />
          </NFormItem>
          <NFormItem label="类型">
            <NSelect
              style="width: 110px;"
              :options="typeOptions"
              clearable
              v-model:value="formValue.type"
            />
          </NFormItem>
          <NFormItem label="操作时间：">
            <NDatePicker
              type="datetimerange"
              style="width: 275px;"
              v-model:value="formValue.dateTimeRange"
              clearable
            />
          </NFormItem>
          <NFormItem>
            <NButton type="primary" size="small" @click="handleSearch">搜索</NButton>
            <NButton size="small" style="margin-left: 10px;" @click="handleClearSearch">清除</NButton>
          </NFormItem>
        </NForm>
        <NDataTable
          size="small"
          :columns="tableColumns"
          :data="tableData"
          :pagination="tablePagination"
          remote
        ></NDataTable>
      </template>
      <template #footer>
        <div style="text-align: right;">
          <NButton type="primary" size="small" @click="handleClickConfirm">关闭</NButton>
        </div>
      </template>
    </NModal>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, h } from 'vue';
  import { NModal, NForm, NFormItem, NInput, NSelect, NDatePicker, NDataTable, NTime, NButton } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  import { accountOperationTypeValName } from '@/enums/account-operation-type.js';

  import { operationLogs } from '@/api/school-teacher.js';

  const { SUCCESS } = resStatusEnum;
  const loading = ref(false);

  let teacherID;
  const modalDisplay = ref(false);
  const modalTitle = ref('');

  const typeOptions = Object.keys(accountOperationTypeValName).map(val => ({ label: accountOperationTypeValName[val], value: Number(val) }));
  function getDefaultFormValue() {
    return {
      keyword: '',
      type: null,
      dateTimeRange: null
    };
  }
  const formValue = reactive(getDefaultFormValue());
  function resetFormValue() {
    Object.assign(formValue, getDefaultFormValue());
  }
  function getSearchParams() {
    const obj = {};
    const keyword = formValue.keyword.trim();
    if (keyword) {
      obj['Teacher[search]'] = keyword;
    }
    if (formValue.type) {
      obj['Teacher[type]'] = formValue.type;
    }
    if (formValue.dateTimeRange) {
      obj['Teacher[start_time]'] = formValue.dateTimeRange[0] / 1000;
      obj['Teacher[end_time]'] = formValue.dateTimeRange[1] / 1000;
    }
    return obj;
  }
  function handleSearch() {
    handlePageChange(1);
  }
  function handleClearSearch() {
    resetFormValue();
    handleSearch();
  }

  const tableColumns = [
    { title: '操作内容', key: 'content', align: 'center' },
    {
      title: '类型',
      width: '130px',
      align: 'center',
      render: ({ type }) => h(
        'span',
        null,
        accountOperationTypeValName[type]
      )
    },
    {
      title: '时间',
      width: '290px',
      align: 'center',
      render: ({ create_time }) => h(
        NTime,
        { time: create_time * 1000 }
      )
    },
  ];
  const tableData = ref([]);
  const tablePagination = reactive({
    page: 1,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true,
    itemCount: 0,
    onChange: handlePageChange,
    onUpdatePageSize: pageSize => {
      tablePagination.pageSize = pageSize;
      handlePageChange(1);
    }
  });
  function getPaginationParams() {
    const { page, pageSize: page_size } = tablePagination;
    return {
      page,
      page_size
    }
  }
  function updateItemCount(count) {
    tablePagination.itemCount = Number(count) || 0;
  }
  function handlePageChange(page) {
    tablePagination.page = page;
    updateTableData();
  }
  function updateTableData() {
    loading.value = true;
    operationLogs({
      'Teacher[id]': teacherID,
      ...getSearchParams(),
      ...getPaginationParams()
    }).then(({ code, data }) => {
      if (code === SUCCESS) {
        const { list, total } = data;
        tableData.value = Array.isArray(list) ? list : [];
        updateItemCount(total);
        modalDisplay.value || (modalDisplay.value = true);
      }
    }).finally(() => {
      loading.value = false;
    });
  }

  function handleModalClosed() {
    
  }
  function handleClickConfirm() {
    modalDisplay.value = false;
  }

  defineExpose({
    initTableData({ username, id }) {
      teacherID = id;
      modalTitle.value = `${username} 操作日志`;
      handleClearSearch();
    }
  });
</script>